import { Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { blurSelector } from '@/redux';
import clsx from 'clsx';
import { IResultTotalProps } from '../types';
import useStyles from './styles';

function ResultTotal({
  horizontal = false,
  hours = '00:00',
  remoteHours = '00:00',
  salary = '0.00',
}: IResultTotalProps): JSX.Element {
  const classes = useStyles();
  const isDashboardVisible = useSelector(blurSelector);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const totalRow = (title: string, value: string, last?: boolean): JSX.Element => (
    <div className={classes.item}>
      <Typography variant="h4" component="h4" className={classes.itemTitle}>
        {title}
      </Typography>
      <Typography
        variant="h3"
        component="p"
        className={clsx(last ? classes.itemValueNotBordered : classes.itemValue, {
          [classes.blured]: isDashboardVisible && isDesktop,
        })}
      >
        {value}
      </Typography>
    </div>
  );

  return (
    <div className={horizontal ? classes.horizontal : classes.vertical}>
      <div className={classes.total}>
        <Typography variant="h2" component="h2" className={classes.title}>
          Total:
        </Typography>
      </div>
      {totalRow('Remote hours', remoteHours)}
      {totalRow('Hours', hours)}
      {totalRow('Salary', salary, true)}
    </div>
  );
}

export default ResultTotal;
