import { memo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@/components/Icons';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Dates } from '@/utils';
import { getMonth, getYear } from 'date-fns';
import useStyles from '../styles';
import { IDatePeriodChangerProps } from '../types';

export default memo(function MonthChanger({
  setPrevDate,
  setNextDate,
  date,
  year,
  ...rest
}: IDatePeriodChangerProps): JSX.Element {
  const classes = useStyles();
  const currentMonth = getMonth(new Date());
  const currentYear = getYear(new Date());

  return (
    <div {...rest} className={classes.datePeriodBlock}>
      <Button
        disabled={date === 0 && year === 2000}
        className={classes.chevronIcon}
        onClick={setPrevDate}
      >
        <ChevronLeftIcon className={clsx(classes.arrowButtonLeft, classes.arrowButton)} />
      </Button>
      <Typography className={classes.datePeriodData}>{`${
        Dates.getMonths(false)[date]
      }, ${year}`}</Typography>
      <Button
        disabled={date >= currentMonth && currentYear === year}
        className={classes.chevronIcon}
        onClick={setNextDate}
      >
        <ChevronRightIcon className={clsx(classes.arrowButtonRight, classes.arrowButton)} />
      </Button>
    </div>
  );
});
