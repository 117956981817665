import StatisticCard from '@/components/Cards/StatisticCard';
import Grid from '@material-ui/core/Grid';
import { EventCard } from '@/components/Cards';
import React from 'react';
import Link from '@material-ui/core/Link';
import useUpcomingEvents from './useUpcomingEvents';
import useStyles from './styles';

const UpcomingEventsCard = () => {
  const { events } = useUpcomingEvents();

  const styles = useStyles();

  return events.length ? (
    <StatisticCard
      title={`Upcoming Event${events.length >= 2 ? 's' : ''}`}
      contentClassName={styles.content}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EventCard
            isBirthDay={events[0].type === 'Birthday'}
            eventDate={events[0]?.date}
            userFullName={
              events[0].type === 'Birthday' ? events[0]?.eventName ?? undefined : undefined
            }
            eventName={events[0].type === 'Birthday' ? undefined : events[0].eventName ?? undefined}
          />
        </Grid>
        {events.length >= 2 && (
          <Grid item xs={12} className={styles.linkBox}>
            <Link href="/time-off">See more upcoming events!</Link>
          </Grid>
        )}
      </Grid>
    </StatisticCard>
  ) : null;
};

export default UpcomingEventsCard;
