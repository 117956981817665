import { useCallback, useState } from 'react';
import { DateFormatUtil } from '@/utils';
import { Periods } from '@/types/periods';
import { getMonth } from 'date-fns';

const useDateChanger = () => {
  const [month, setMonth] = useState(DateFormatUtil.formatToMonthNumber(new Date()) - 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [period, setPeriod] = useState(Periods.Month);

  const nextMonth = useCallback(() => {
    setMonth((prevState) => {
      if (prevState === 11) {
        setYear((state) => state + 1);
        return 0;
      }
      return prevState + 1;
    });
  }, []);

  const prevMonth = useCallback(() => {
    setMonth((prevState) => {
      if (prevState === 0) {
        setYear((state) => state - 1);
        return 11;
      }

      return prevState - 1;
    });
  }, []);

  const nextYear = useCallback(() => {
    setYear((state) => state + 1);
  }, []);

  const prevYear = useCallback(() => {
    if (year > 2000) {
      setYear((state) => state - 1);
    }
  }, [year]);

  const changePeriod = useCallback(
    (value: Periods) => {
      setPeriod(value);
      if (value === Periods.Month && month > getMonth(new Date())) {
        setMonth(getMonth(new Date()));
      }
    },
    [month],
  );

  return {
    period,
    month,
    year,
    changePeriod,
    nextMonth,
    prevMonth,
    nextYear,
    prevYear,
  };
};

export default useDateChanger;
