import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import lineLight from '@/assets/svg/dashboard/line-light.svg';
import lineDark from '@/assets/svg/dashboard/line-dark.svg';
import lineGreen from '@/assets/svg/dashboard/line-green.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 7,
      boxShadow: '3px 0px 15px rgba(78, 81, 97, 0.14)',
      background: theme.palette.type !== 'dark' ? 'white' : '#151529',
      height: '200px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      position: 'relative',
    },
    content: {
      paddingBottom: '0 !important',
      width: '100%',
      height: '100%',
      zIndex: 2,
    },
    item: {
      width: '90%',
      margin: theme.spacing(3.125, 0),
      [theme.breakpoints.up('lg')]: {
        margin: '24px 0px 57px 16px',
      },
    },
    cardIcon: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
    },
    birthdayIcon: {
      maxWidth: 204,
      height: 179,
      paddingRight: 3,
    },
    partyIcon: {
      maxWidth: 228,
      height: 160,
      paddingRight: 7,
    },
    description: {
      fontSize: 18,
      lineHeight: '25px',
      marginBottom: 25,
      color:
        theme.palette.type !== 'dark' ? theme.palette.text.secondary : theme.palette.common.white,
    },
    title: {
      fontWeight: 600,
      color:
        theme.palette.type !== 'dark' ? theme.palette.text.secondary : theme.palette.common.white,
    },
    body: {
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: 28,
      lineHeight: '39px',
      letterSpacing: '0.16px',
    },
    icon: {
      fontSize: 45,
      marginRight: 15,
      [theme.breakpoints.up('lg')]: {
        marginRight: 20,
      },
    },
    dollarIcon: {
      width: '26.5px',
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.875, 0),
      fontSize: 12,
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      color: '#AFAFAF',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 35,
      },
    },
    footerIcon: {
      fontSize: 12,
      marginRight: 5,
    },
    divider: {
      height: 5,
      width: '100%',
      backgroundImage: `url(${theme.palette.type === 'light' ? lineLight : lineDark})`,
      backgroundRepeat: 'no-repeat',
      background: '',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },
    greenLine: {
      backgroundImage: `url(${lineGreen})`,
    },
  }),
);

export default useStyles;
